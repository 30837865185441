<template>
  <div class="timerButton">
    <basic-button
      :title="buttonTitle"
      :type="buttonStyle"
      :styles="buttonStyle"
      :disabled="isdisabled"
      :class="{ cursor: isButtonTimer }"
      @button-clicked="clicked"
    />
  </div>
</template>

<script>
import BasicButton from '@/views/ui/components/buttons/BasicButton'

export default {
  name: 'TimerButton',
  components: { BasicButton },
  props: {
    timerInterval: {
      type: Number,
      default: 120,
    },
  },
  data() {
    return {
      isButtonTimer: true,
      timerId: null,
      timerValue: 0,
    }
  },
  computed: {
    buttonTitle() {
      if (this.isButtonTimer) {
        let minutes = Math.trunc(this.timerValue / 60)
        let minutesText = minutes < 10 ? '0' + String(minutes) : minutes
        let seconds = this.timerValue % 60
        let secondsText = seconds < 10 ? '0' + String(seconds) : seconds

        return `Отправить код повторно через ${minutesText}:${secondsText}`
      }

      return 'Запросить код еще раз'
    },
    buttonStyle() {
      return this.isButtonTimer ? 'secondary not-allowed' : 'primary'
    },
    isdisabled() {
      return Boolean(this.isButtonTimer)
    },
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  methods: {
    startTimer() {
      this.timerValue = this.timerInterval
      this.isButtonTimer = true
      this.timerId = setInterval(() => {
        this.timerValue--
      }, 1000)
      setTimeout(() => {
        clearInterval(this.timerId)
        this.isButtonTimer = false
      }, this.timerValue * 1000)
    },
    clicked() {
      if (!this.disabled) {
        this.startTimer()
        this.$emit('button-clicked')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.cursor
  cursor: default
</style>
